import getEnvVariable from "../configs/env";

class API {
  /**
   * @param {string} config
   */
  static async get(config) {
    try {
      const { url, token } = this.parseConfig(config);
      const response = await fetch(`${getEnvVariable("API_URL")}${url}`, {
        method: "GET",
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      });
      if (response.status > 300) {
        throw new Error((await response.json()).message);
      }
      return {
        status: response.status,
        data: await response.json(),
      };
    } catch (error) {
      console.error(error);
      this.checkTokenExpired();
      return {
        status: 400,
        error: String(error),
      };
    }
  }

  /**
   * @param {{ url: string; method?:"GET"|"POST"|"PUT"; data: Record<string,unknown>; }} config
   */
  static async post(config) {
    try {
      const { url, token, data, method } = this.parseConfig(config);

      const apiUrl = !url.startsWith("/bluesage-validate-license")
        ? getEnvVariable("API_URL")
        : getEnvVariable("API_URL").replace("admin", "lo");

      const response = await fetch(`${apiUrl}${url}`, {
        method: method ?? "POST",
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
        body: data instanceof FormData ? data : JSON.stringify(data), //if we're posting a form don't try to JSONify it
      });
      try {
        if (response.status > 300) {
          const error = await response.json();
          return {
            status: response.status,
            errorId: error.error,
            error: error.message,
          };
        }
        return {
          status: response.status,
          data: await response.json(),
        };
      } catch (error) {
        if (response.status < 300 && response.status >= 200) {
          return {
            status: response.status,
            data: undefined,
          };
        } else {
          return {
            status: response.status,
            error: String(error),
          };
        }
      }
    } catch (error) {
      console.error(error);
      this.checkTokenExpired();
      return {
        status: 400,
        error: String(error),
      };
    }
  }
  /**
   * @param {string | { url: string; method?: "GET"|"POST"; token?: string; data: Record<string,unknown>; }} config
   */
  static parseConfig(config) {
    if (typeof config === "string") {
      return {
        url: config,
        token: localStorage.getItem("NFTdoorAdminToken"),
      };
    } else {
      return {
        url: config.url,
        method: config.method,
        token: config.token ?? localStorage.getItem("NFTdoorAdminToken"),
        data: config.data,
      };
    }
  }

  static checkTokenExpired() {
    const expToken = localStorage.getItem("NFTdoorAdminTokenExp");

    if (expToken) {
      const dateNow = new Date().getTime();
      const authDate = Number(expToken ?? 0);

      if (dateNow > authDate) {
        localStorage.removeItem("NFTdoorAdminTokenExp");
        window.location.reload();
      }
    }
  }
}

export default API;
